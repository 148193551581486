<template>
  <div class="home">
    <v-row align="center">
      <v-col class="text-center">
        <img src="../assets/rayoga.svg">
      </v-col>
      <!--<v-spacer />
      <v-col>
        <v-btn
          v-if="!isAuthenticated"
          outlined
          color="#C1563E"
          :to="{ name: 'LoginSignupRoute' }"
        >
          Login to start
        </v-btn>
      </v-col>-->
    </v-row>
    <v-row>
      <v-col class="text-center">
        <img src="../assets/2025-march-logo.svg">
      </v-col>
    </v-row>

    <v-row v-if="isAuthenticated">
      <div class="text-center">
        <p class="mb-2">Logged in as {{ user.full_name }} ({{ user.email }})</p>
        <small><a href="#" @click.prevent="logout">Is this not you?</a></small>
      </div>
    </v-row>

    <v-row>
      <v-col>
        <p>Dive into Ra Yoga's 30-day challenge to deepen your practice and uncover new potential within.</p>

        <p>Consistency and dedication are key to unlocking the physical and mental benefits of yoga. Commit to 30 classes throughout March to nurture mindfulness and reduce stress.</p>
          
        <p>If RA’s 13 years of experience  has taught us anything, a challenge like this is the perfect way to build deeper connections with yourself and RA’s community!</p>
          
        <p>New levels of wellness and self-discovery await!</p>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="mb-5">
        <v-btn
          block
          x-large
          color="#CB6419"
          dark
          depressed
          v-if="!isAuthenticated"
          :to="{ name: 'LoginSignupRoute' }">
          Login to start challenge
        </v-btn>
        <v-btn
          block
          x-large
          color="#CB6419"
          dark
          depressed
          v-if="isAuthenticated"
          @click="openConfirmDialog">
          Join the challenge
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="mb-5">
        <img style="min-width: 100%;" src="../assets/30-classes.svg">
      </v-col>
    </v-row>

    <v-row>
      <v-col class="text-center">
        <h3>Complete the 30-Day Challenge and you’ll be entered into a raffle to win...</h3>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="prize-container">
        <h2 class="prize-header"><img src="../assets/prize-icon.svg"> Grand Prize - $1,000 Value</h2>
        <ul class="prize-list">
          <li>3 months of unlimited Premium</li>
          <li>15 guest credits</li>
          <li>Any top &amp; bottom</li>
        </ul>
      </v-col>
      <v-col cols="12" class="prize-container">
        <h2 class="prize-header"><img src="../assets/prize-icon.svg"> Second Prize - $750 Value</h2>
        <ul class="prize-list">
          <li>2 months of unlimited Premium</li>
          <li>10 guest credits</li>
          <li>Any bottom</li>
        </ul>
      </v-col>
      <v-col cols="12" class="prize-container">
        <h2 class="prize-header"><img src="../assets/prize-icon.svg"> Third Prize - $500 Value</h2>
        <ul class="prize-list">
          <li>1 month of unlimited Premium</li>
          <li>5 guest credits</li>
          <li>Any top</li>
        </ul>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <p>There’s an easy way to earn extra raffle entries to increase your chances of winning! Learn more after signing up!</p>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="mb-5">
        <v-btn
          block
          x-large
          color="#CB6419"
          dark
          depressed
          v-if="!isAuthenticated"
          :to="{ name: 'LoginSignupRoute' }">
          Login to start challenge
        </v-btn>
        <v-btn
          block
          x-large
          color="#CB6419"
          dark
          depressed
          v-if="isAuthenticated"
          @click="openConfirmDialog">
          Join the challenge
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="mb-5">
        <img style="min-width: 100%;" src="../assets/2025-quote-block.svg">
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" class="mb-5">
        <div class="white-block pa-10">
          <v-row justify="center">
            <img style="min-width: 80%;" class="mb-8" src="../assets/sun.svg">
            <h2 class="text-center mb-3">Want to join the challenge, but need a membership?</h2>
            <p style="font-size: 12px;" class="mb-7">For a limited time, you can signup for a one-month unlimited membership for $149.99. Here are some more details: No contract required. This membership must be purchased by March 5th and expires on April 5th, 2025.</p>
            <v-btn
              block
              x-large
              color="#CB6419"
              dark
              depressed
              href="https://landing.rayoga.com/?promo_id=55"
              target="_BLANK">
              Buy Challenge Membership
            </v-btn>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <v-dialog
      v-model="confirmDialog"
      closable
      max-width="480"
      >
      <v-card class="confirm-dialog pa-8">
        <v-card-text>
          <h1 class="text-center mb-4">Let's Go!</h1>
          <p>A few things to note before joining the challenge.</p>
          <ul>
            <li>You will use your regular pricing options to book classes.</li>
            <li>The challenge itself has no cost associated with it.</li>
            <li>No penalty for not completing the challenge.</li>
            <li>Must attend 30 classes in March 2025 to be eligible for prize winnings.</li>
            <li>The more people you get to participate with you, the more raffle entries you receive!</li>
          </ul>
          <v-checkbox v-model="confirmCheckbox" color="#ffffff" label="I understand that by participating in this challenge, my photos, videos, and text may be used for positive, promotional purposes."></v-checkbox>
          <v-col class="text-center">
            <v-btn
              color="#EFA83C"
              dark
              depressed
              @click="createChallengeSignup"
              :disabled="isConfirmPending || !confirmCheckbox"
              x-large>
              Confirm
            </v-btn>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
    
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// @ is an alias to /src

export default {
  name: 'Home',

  data() {
    return {
      confirmDialog: false,
      confirmCheckbox: false,
      isConfirmPending: false,
    }
  },

  components: {
  },

  computed: {
    ...mapGetters([
      'isAuthenticated',
      'hasSignedUp'
    ]),
    user() {
      return this.$store.state.user
    },
  },

  methods: {
    openConfirmDialog() {
      this.confirmDialog = true
    },
    async createChallengeSignup() {
      this.isConfirmPending = true
      await this.$store.dispatch('createChallengeSignup', {
        challengeId: this.$store.state.challengeId,
        customerId: this.user.id,
        customerName: this.user.full_name,
        customerEmail: this.user.email,
        referral: this.$store.state.ref,
      })
      this.confirmDialog = false
      this.isConfirmPending = false

      this.$router.push('/challenge')
    },
    logout() {
      window.localStorage.removeItem('vuex')
      window.location.href = 'https://challenge.rayoga.com?prompt=1'
    },
  },

  mounted() {
    console.log(this.$route.query)
    if (this.$route.query.ref) {
      this.$store.state.ref = this.$route.query.ref
    }

    if (this.$route.query.prompt) {
      this.$store.state.prompt = true
    }

    if (this.hasSignedUp) {
      this.$router.push('/challenge')
    }
  }
}
</script>

<style lang="scss">
.white-block {
  background-color: #ffffff;
  border-radius: 13px;
  box-shadow: 0px 2px 6px rgba(0,0,0,0.2);
}

.prize-container {
  text-align: center;

  .prize-header {
    font-size: 20px;
    font-weight: 900;
  }

  .prize-list {
    font-size: 14px;
    list-style-position: inside;
  }

  p {
    font-size: 14px;
  }
}

.confirm-dialog {
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #3F523E !important;
  background-size: cover;
  border-radius: 8px;
  color: #ffffff !important;
  font-size: 16px;
  font-weight: 100 !important;

  ul {
    list-style: none;
    li {
      background-image: url('~@/assets/arrow.svg');
      background-position-y: 13px;
      padding-left: 30px;
      margin-bottom: 10px;
    }
  }

  &.theme--light.v-card > .v-card__text,
  .v-label.theme--light {
    color: #ffffff !important;
  }

  .theme--light.v-card > .v-card__text,
  .theme--light.v-input {
    color: #ffffff !important;
  }
}
</style>
